import React, { useState }  from "react"
import _ from "lodash"
import { Header, Icon, Breadcrumb, Label } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ConceptMatrixPage = (args) => {
  const [selectedConcepts, setSelectedConcepts] = useState({});
  const toggleConceptInSelection = (concept) => {
    //console.log("toogleConceptInSelection("+concept+")");
    setSelectedConcepts(({ [concept]: set, ...rest }) => set ? ({...rest}) : ({[concept]: true, ...rest}));
  };
  const concepts = args.data.concepts.nodes;
  const misconceptions = args.data.misconceptions.nodes;
  const totalCount = (concept) => {
    const c = _.find(
      args.data.misconceptionConcepts.group,
      ['fieldValue', concept.name]);
    return c ? c.totalCount : 0;
  };
  const misconceptionContainsAllSelectedConcepts = (misconception) => {
    if (Object.entries(selectedConcepts).length===0) {
      return false;
    }
    if (misconception.frontmatter.concepts) {
      for (const [conceptName] of Object.entries(selectedConcepts)) {
        if (!misconception.frontmatter.concepts.includes(conceptName)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };
  const bgcolor = (concept, ci, misconception, mi) => {
    //console.log(concept, ci, misconception, mi);
    const conceptSelected = concept ? selectedConcepts[concept.name] : false;
    const misconceptionSelected = misconception ? misconceptionContainsAllSelectedConcepts(misconception) : false;
    let hue;
    let saturation;
    let luminance = (95-((ci+1)%2 + (mi+1)%2) * 5);
    if (conceptSelected) {
      if (misconceptionSelected) {
        hue = 120;
        saturation = 50;
        luminance *= 0.9;
      } else {
        hue = 240;
        saturation = 100;
      }
    } else {
      if (misconceptionSelected) {
        hue = 60;
        saturation = 65;
        luminance *= 0.9;
      } else {
        hue = 0;
        saturation = 0;
      }
    }
    return "hsl("+hue+","+saturation+"%,"+luminance+"%)";
  }
  return <Layout>
    <SEO title="Concept Matrix" />
    <div style={{ overflowX: "auto", marginLeft: "10px", marginTop: "50px", marginBottom: "50px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Concept Matrix</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          Concept Matrix
          <Header.Subheader>
            {misconceptions.length} Misconceptions vs. {' '}
            {concepts.length} Concepts
          </Header.Subheader>
        </Header.Content>
      </Header>
      {
        Object.entries(selectedConcepts).length ?
        <>
          <div>
            Misconceptions related to &nbsp;
              {Object.entries(selectedConcepts).map(([c]) => (
                <Label
                  key={`concepts-${c}`}
                  as={Link}
                  to={`/concepts/${_.kebabCase(c)}`}
                  color="brown"
                >
                  {_.startCase(c)}
                </Label>
              )).reduce((acc,curr) => <>{acc} or {curr}</>)}
          </div>
        </>
        : null
      }
      <table>
        <thead>
          <tr style={{height: '150px'}}>
            <th></th>
            <th></th>
            {concepts.map(concept => (
              <td key={concept.name}>
                <div style={{width: '20px'}}>
                  <div style={{width: '300px', transformOrigin: 'bottom left', transform: 'translateX(19px) translateY(65px) rotate(-90deg)'}}>
                    <Link to={`/concepts/${_.kebabCase(concept.name)}/`}>
                      {concept.name}
                    </Link>
                  </div>
                </div>
              </td>
            ))}
          </tr>
          <tr style={{height: '30px'}}>
            <td style={{textAlign: 'right', color: 'grey'}}>click numbers to filter</td>
            <td style={{textAlign: 'right', color: 'grey'}}>&rarr;</td>
            {concepts.map((concept, ci) => (
              <th
                key={concept.name}
                style={{backgroundColor: bgcolor(concept, ci, null, -1), cursor: 'pointer'}}
                onClick={() => toggleConceptInSelection(concept.name)}
              >
                {totalCount(concept)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {misconceptions.filter(misconception => {
              if (Object.entries(selectedConcepts).length===0) {
                return true;
              }
              if (misconception.frontmatter.concepts) {
                for (const [conceptName] of Object.entries(selectedConcepts)) {
                  if (misconception.frontmatter.concepts.includes(conceptName)) {
                    return true;
                  }
                }
                return false;
              } else {
                return false;
              }
            }).map((misconception, mi) => (
            <tr key={misconception.fields.pl+"/"+misconception.fields.name}>
              <td style={{backgroundColor: bgcolor(null, -1, misconception, mi)}}>
                <Link to={`/misconceptions/${misconception.fields.pl}/${misconception.fields.name}/`}>
                  {misconception.fields.name}
                </Link>
              </td>
              <th style={{backgroundColor: bgcolor(null, -1, misconception, mi)}}>
                {misconception.frontmatter.concepts ? misconception.frontmatter.concepts.length : 0}
              </th>
              {concepts.map((concept, ci) => (
                <td style={{textAlign: 'center', backgroundColor: bgcolor(concept, ci, misconception, mi)}} key={concept.name}>
                  {misconception.frontmatter.concepts && misconception.frontmatter.concepts.find(c => c===concept.name) ? '\u2b24' : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Layout>;
}

export default ConceptMatrixPage

export const pageQuery = graphql`
  query {
    concepts: allFile(
      filter: {absolutePath: {glob: "**/src/data/concepts/*.md"}},
      sort: {fields: name}
    ) {
      nodes {
        name
      }
    }
    misconceptionConcepts: allMdx {
      group(field: frontmatter___concepts) {
        fieldValue
        totalCount
      }
    }
    misconceptions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      nodes {
        fields {
          slug
          name
          pl
        }
        frontmatter {
          status
          concepts
          shortDescription
        }
      }
    }
  }
`
